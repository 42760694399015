@import "@/global/variables.module.scss";

html {
  font-size: $base-font-size;
  /* Scrollbar */
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $light-bg-color;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: $border-radius;
    background: $gray;
    box-shadow: 0px 1px 12px 0px rgba(14, 18, 62, 0.1);
  }

  body {
    font-family: "Assistant", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    color: $black;
    font-size: 0.875rem;

    .app {
      height: 100vh;
      display: flex;
      flex-direction: column;
      background: $dark-bg-color;

      .content {
        &.protected {
          @media (max-width: 568px) {
            padding: 80px 0 0 0;
          }
          padding: 80px 15px 0 15px;
          transition: $transition;
          margin-left: 300px;
          width: calc(100% - 300px);
          overflow-y: auto;
        }
      }
      &.nav-menu-collapsed {
        .content {
          &.protected {
            margin-left: 0;
            width: 100%;
          }
        }
      }
      .page {
        max-width: 69.75rem;
        margin: 0 auto;
      }
    }

    *,
    *::after,
    *::before {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }

    h2 {
      font-size: 1.5rem;
    }

    h3 {
      font-size: 1rem;
    }
    h4 {
      font-size: 0.875rem;
    }

    a {
      text-decoration: inherit;
      color: $black;
    }

    b,
    strong {
      font-weight: bolder;
    }

    table {
      text-indent: 0;
      border-color: inherit;
      border-collapse: collapse;
    }

    button,
    input,
    optgroup,
    select,
    textarea {
      font-family: inherit;
      font-feature-settings: inherit;
      font-variation-settings: inherit;
      font-size: 100%;
      font-weight: inherit;
      line-height: inherit;
      color: inherit;
      margin: 0;
      padding: 0;
    }

    button,
    select {
      text-transform: none;
    }

    button,
    input[type="submit"],
    input[type="reset"] {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
    }

    :-moz-focusring {
      outline: auto;
    }

    :-moz-ui-invalid {
      box-shadow: none;
    }

    progress {
      vertical-align: baseline;
    }

    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      height: auto;
    }

    [type="search"] {
      -webkit-appearance: textfield;
      outline-offset: -0.125rem;
    }

    ::-webkit-search-decoration {
      -webkit-appearance: none;
    }

    ::-webkit-file-upload-button {
      -webkit-appearance: button;
      font: inherit;
    }

    summary {
      display: list-item;
    }

    blockquote,
    dd,
    dl,
    figure,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    hr,
    p,
    pre {
      margin: 0;
    }

    fieldset {
      margin: 0;
    }

    fieldset,
    legend {
      padding: 0;
    }

    menu,
    ol,
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    dialog {
      padding: 0;
    }

    textarea {
      resize: vertical;
    }

    input::-moz-placeholder,
    textarea::-moz-placeholder {
      opacity: 1;
    }

    input::placeholder,
    textarea::placeholder {
      opacity: 1;
    }

    [role="button"],
    button {
      cursor: pointer;
    }

    :disabled {
      cursor: default;
    }

    audio,
    canvas,
    embed,
    iframe,
    img,
    object,
    svg,
    video {
      display: block;
      vertical-align: middle;
    }

    img,
    video {
      max-width: 100%;
      height: auto;
    }

    [hidden] {
      display: none;
    }
  }
}
