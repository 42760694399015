$base-font-size: 16px;
$border-radius: 0;
$transition: all 0.3s ease-in-out;
$input-shadow: 0px 3px 4px 0px rgba(70, 101, 67, 0.1);

// Colors
$primary: #00c896;
$secondary: #ffecd2;
$tertiary: #00c896;
$black: #202121;
$white: #ffffff;
$gray: #c4c4c4;
$error: #f26668;
$warning: #f0c644;
$success: $primary;
$border-color: $black;
$light-bg-color: #ffffff;
$dark-bg-color: #fbfbfe;

// Breakpoints
$xs: 25rem;
$sm: 35.5rem;
$md: 48rem;
$lg: 64rem;
$xl: 80rem;
$xxl: 120rem;
$xxxl: 160rem;

$variables: (
  "baseFontSize": $base-font-size,
  "borderRadius": $border-radius,
  "transition": $transition,
  "inputShadow": $input-shadow,

  "primary": $primary,
  "secondary": $secondary,
  "tertiary": $tertiary,
  "black": $black,
  "white": $white,
  "gray": $gray,
  "error": $error,
  "warning": $warning,
  "success": $success,
  "borderColor": $border-color,
  "lightBgColor": $light-bg-color,
  "darkBgColor": $dark-bg-color,

  "xs": $xs,
  "sm": $sm,
  "md": $md,
  "lg": $lg,
  "xl": $xl,
  "xxl": $xxl,
  "xxxl": $xxxl,
);

:export {
  @each $var, $value in $variables {
    #{(unquote($var))}: $value;
  }
}
